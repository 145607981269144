<template>
  <div class="info center">
    <bread-crumb></bread-crumb>
    <div class="search-card">
      <el-form :model="searchForm" label-width="116px">
        <el-form-item label="位置区域：">
          <el-row class="types-row">
            <el-select v-model="regions.province" clearable @change="handleProvince" @clear="clearDq">
              <el-option v-for="item in regions.provinces" :key="item.id" :label="item.name" :value="item.value"></el-option>
            </el-select>
            <el-select v-model="regions.city" clearable @change="handleCity">
              <el-option v-for="item in regions.citys" :key="item.id" :label="item.name" :value="item.value "></el-option>
            </el-select>
            <el-select v-model="regions.county" clearable @change="handleCounty">
              <el-option v-for="item in regions.counties" :key="item.id" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-row>
        </el-form-item>
        <el-form-item label="登记单元类型：">
          <el-row class="types-row">
            <span v-for="(item, index) of searchForm.types.data" :key="index"
                  :class="{'checked': item.isChecked}"
                  class="tag-span"
                  @click="handleCheck(item, searchForm.types)">{{ item.mc }}</span>
          </el-row>
        </el-form-item>
        <el-form-item label="登记成果：">
          <el-row class="types-row">
            <span v-for="(item, index) of djdyList1" :key="index"
                  class="tag-span"
                  @click="detail(item.id)">{{ item.djdymc }}</span>
          </el-row>
          <el-row v-if="showMore" class="types-row">
            <span v-for="(item, index) of djdyList2" :key="index" class="tag-span"
                  @click="detail(item.id)">{{ item.djdymc }}</span>
          </el-row>
          <el-row class="types-row">
            <div class="moreBtn">
              <i class="icon-line"></i>
              <a @click="showmore">{{ showMore ? '收起' : '更多' }}<i :class="[showMore? 'el-icon-arrow-up' : 'el-icon-arrow-down']"></i></a>
              <i class="icon-line"></i>
            </div>
          </el-row>

        </el-form-item>
      </el-form>
    </div>
    <!-- 根据是否有搜索结果，判断显示结果列表和缺省页 -->
    <div v-if="!showNotFound">
      <el-tabs class="hot-tabs">
        <el-tab-pane>
                    <span slot="label" @click="swatchLx('new')">
                        最新鲜
                        <i class="el-icon-caret-bottom"></i>
                    </span>
          <!-- 使用该组件时，必须传递数据 -->
          <hot-list :listData="freshData"></hot-list>
        </el-tab-pane>
        <el-tab-pane>
                    <span slot="label" @click="swatchLx('hot')">
                        最热门
                        <i class="el-icon-caret-bottom"></i>
                    </span>
          <!-- 使用该组件时，必须传递数据 -->
          <hot-list :listData="freshData"></hot-list>
        </el-tab-pane>
      </el-tabs>
      <div class="pagination">
        <el-pagination
            :current-page="current"
            :page-size="size"
            :page-sizes="[12, 24, 36, 48]"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
            @next-click="handleCurrentChange"
            @prev-click="handleCurrentChange"
            @size-change="handleSizeChange">
        </el-pagination>
      </div>
    </div>
    <not-found v-else></not-found>
  </div>
</template>
<script>
import hotList from '@/components/hotList'
import breadCrumb from '@/components/common/breadCrumb'
import pagination from '@/components/common/pagination'
import infoList from "../home/infoList";
import notFound from '@/components/notFound'
import {getEnumList, getSearchEnumList} from "@/api/homeService";
import {getDyInfoBySearch} from "@/api/dyInfoService";
import regionsData from '@/api/regions'

export default {
  components: {
    hotList,
    infoList,
    breadCrumb,
    pagination,
    notFound
  },

  data() {
    return {
      showMore: false,  //更多是否展示
      current: 0,
      size: 12,
      total: 0,

      isClick: true,
      dq: "",
      lx: 'new',
      zrbhd: '',
      jb: '',
      regionsData: regionsData,
      regions: {
        province: '',
        provinces: [],
        city: '',
        citys: [],
        county: '',
        counties: []
      },
      searchForm: {
        types: {
          title: '资源类型',
          isClick: this.isClick,
          data: [
            {
              mc: '全部',
              isChecked: true
            }
          ]
        },
        areas: {
          title: '自然保护地',
          isClick: !this.isClick,
          data: [
            {
              mc: '全部',
              isChecked: false
            }
          ]
        },
        jb: {
          title: '级别',
          data: [
            {
              mc: '全部',
              isChecked: true
            },
            {
              mc: '国家级',
              isChecked: false,
              mcdm: 'gjj'
            }, {
              mc: '省级',
              isChecked: false,
              mcdm: 'sj'
            }]
        }
      },
      freshData: [],
      showNotFound: false,
      djdyList1: [],
      djdyList2: [],
    }
  },
  mounted() {
    this.dq = this.$route.query.dq;
    this.zrbhd = this.$route.query.zylx;
    this.jb = this.$route.query.jb;
    this.searchEnumListInit();
    this.getCitys('xzq0', 'provinces')
    this.againSearch();
    this.dqmanage();
    this.getDjdyByDjsj();
  },
  methods: {
    handleSizeChange(val) {
      this.size = val;
      this.search()
    },
    handleCurrentChange(val) {
      this.current = val;
      this.search()
    },
    //地区联动
    dqmanage() {
      if (this.dq != null) {
        if (this.dq === '000' || this.dq.length === 2) {
          this.regions.province = this.dq;
        } else if (this.dq.length > 2) {
          this.getCitys('xzq' + this.dq.slice(0, 2), 'citys')
          this.regions.province = this.dq.slice(0, 2);
          this.regions.city = this.dq.slice(0, 4);
          if (this.dq.length > 4) {
            this.getCitys('xzq' + this.dq.slice(0, 4), 'counties')
            this.regions.county = this.dq;
          }
        }
      }

    },

    /**
     * 获取地区枚举
     * @param fid
     */
    async getDqMj(fid) {
      const mj = {
        'fid': fid,
        'zudm': 'xzqEnum'
      }
      const result = await getEnumList(mj)
      if (result.code === '0') {
        return result.data
      }
    },
    //地区选择
    getCitys(fid, qy) {
      this.getDqMj(fid).then(response => {
        if (response) {
          const data = response.map((value, i) => {
            return {
              id: value.id,
              name: value.mc,
              value: value.mcdm,
            }
          })
          if (qy === 'provinces') {
            this.regions.provinces = data
          } else if (qy === 'citys') {

            let xx = [{
              id: '1',
              name: '全部',
              value: ""
            }];
            this.regions.citys = xx.concat(data)
            // this.regions.city = data[0].value
            // this.getCitys(data[0].id, 'counties')
          } else if (qy === 'counties') {
            let xx = [{
              id: '1',
              name: '全部',
              value: ""
            }];
            this.regions.counties = xx.concat(data)
            // this.regions.county = data[0].value
          }

        }
      })
    },
    /**
     * 类型切换
     * */
    handleCheck(curr, obj) {
      if (obj.title == '资源类型' || obj.title == '自然保护地') {
        this.searchForm.types.data.map(item => {
          item.isChecked = false
        })
        this.searchForm.areas.data.map(item => {
          item.isChecked = false
        })
        this.zrbhd = curr.mcdm;
      } else {
        this.searchForm.jb.data.map(item => {
          item.isChecked = false
        })
        this.jb = curr.mcdm;
      }
      curr.isChecked = !curr.isChecked
      this.againSearch();
    },
    /**
     * 首页搜索页面条件初始化
     * 不用传参
     * @constructor
     */
    searchEnumListInit() {
      getSearchEnumList().then(res => {
        if (res.code === "0") {
          //资源类型赋值 选中
          let zylx = res.data.zylx;
          for (let i = 0; i < zylx.length; i++) {
            if (zylx[i].mcdm === this.zrbhd) {
              this.searchForm.types.data[0].isChecked = false
              zylx[i].isChecked = true
            } else {
              zylx[i].isChecked = false;
            }

            this.searchForm.types.data.push(zylx[i])
          }
          //自然保护区赋值选中
          let zrbhq = res.data.zrbhq;
          for (let i = 0; i < zrbhq.length; i++) {
            if (zrbhq[i].mcdm === this.zrbhd) {
              this.searchForm.areas.data[0].isChecked = false
              zrbhq[i].isChecked = true
            } else {
              zrbhq[i].isChecked = false;
            }
            this.searchForm.areas.data.push(zrbhq[i]);
          }

        }
      })
      //级别选中
      for (let i = 0; i < this.searchForm.jb.data.length; i++) {
        this.searchForm.jb.data[i].isChecked = this.jb === this.searchForm.jb.data[i].mcdm;
      }
    },
    //地区清空
    clearDq() {
      this.dq = null;
      this.againSearch();
    },

    /**
     * 切换类型
     * */
    swatchLx(lx) {
      this.lx = lx;

      this.againSearch();
    },
    /**
     * 重新查询
     * */
    againSearch() {
      this.current = 1
      this.search()
    },
    /**
     * 搜索单元
     */
    search() {
      if (this.regions.county != null && this.regions.county !== "") {
        this.dq = this.regions.county
      } else if (this.regions.city != null && this.regions.city !== "") {
        this.dq = this.regions.city
      } else if (this.regions.province != null && this.regions.province !== "") {
        this.dq = this.regions.province
      }
      const search = {
        dq: this.dq,
        zylx: this.zrbhd,
        jb: this.jb,
        lx: this.lx,
        start: this.current,
        size: this.size
      }
      getDyInfoBySearch(search).then(res => {
        if (res.code === "0") {

          let data = res.data
          for (let i = 0; i < data.length; i++) {
            data[i].followText = "关注"
            data[i].isFollow = false
            data[i].span = 6;
          }
          this.freshData = data;
          this.total = res.total
          this.showNotFound = this.freshData.length <= 0;
        }
      })
    },
    //获取顶层登记单元
    getDjdyByDjsj() {
      const search = {"lx": "new", "start": 0, "size": 10, "order": "fbsj", "sort": "desc"}
      getDyInfoBySearch(search).then(res => {
        if (res.code === "0") {
          let data = res.data
          for (let i = 0; i < data.length; i++) {
            if (i < 5) {
              this.djdyList1.push(data[i])
            } else {
              this.djdyList2.push(data[i])
            }
          }
        }
      })
    },

    // 省
    handleProvince(currValue) {
      if (currValue === '000') {
        this.regions.city = null
        this.regions.citys = null
        this.regions.county = null
        this.regions.counties = null
      } else {
        const data = this.regions.provinces;
        let fid = '';
        for (let i = 0; i < data.length; i++) {
          if (data[i].value === currValue) {
            fid = data[i].id;
            break;
          }
        }
        this.regions.city = null
        this.regions.county = null
        this.getCitys(fid, 'citys');
      }
      this.againSearch()
    },
    /**
     * 市查询
     * @param currValue
     */
    handleCity(currValue) {
      if (currValue === '') {
        this.regions.county = null
        this.regions.counties = null
      } else {
        const data = this.regions.citys;
        let fid = '';
        for (let i = 0; i < data.length; i++) {
          if (data[i].value === currValue) {
            fid = data[i].id;
            break;
          }
        }
        this.regions.county = null
        this.getCitys(fid, 'counties');
      }
      this.againSearch()
    },
    /**
     * 县查询
     * @param currValue
     */
    handleCounty(currValue) {
      this.againSearch()
    },
    /**
     * 登记单元更多展示
     */
    showmore() {
      this.showMore = !this.showMore;
    },
    /**
     * 根据当前单元号跳转
     * @param currentId
     */
    detail(currentId) {
      this.$router.push({
        name: 'infoDetail',
        query: {
          zrzyDyId: currentId
        }
      })
    },
  },

}
</script>
<style lang="scss">
.pagination {
  text-align: center;
  margin: 20px 0;

  .el-pagination {
    font-weight: 500;
  }
}

.info {
  .search-card {
    border-radius: 0;

    .el-form {
      margin-top: 10px;
      border-bottom: 1px solid #ccc;

      .el-form-item {
        margin: 0 16px;
        margin-bottom: 8px;
        border-bottom: 1px dashed #ccc;
        padding-bottom: 0px;

        .el-form-item__label {
          font-size: 16px;
          color: #039BE5;
          text-align: left;
          padding-right: 0;
        }

        .types-row {
          .tag-span {
            float: left;
            cursor: pointer;
            color: #808080;
            font-size: 16px;
            line-height: 16px;
            padding: 12px 13px;
            margin-bottom: 5px;
            margin-right: 5px;
          }

          .el-select {
            margin-right: 20px;
          }

          .tag-span.checked {
            color: #fff;
            background-color: #039BE5;
          }

          .moreBtn {
            height: 18px;
            padding-bottom: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #0080FF;

            a {
              margin: 0px 8px 0px 8px;
            }

            .icon-line {
              width: 50px;
              height: 1px;
              background: #999999;
              border-radius: 1px;
            }
          }
        }
      }

      .el-form-item:first-child {
        padding-bottom: 10px;
      }

      .el-form-item:last-child {
        border-bottom: none;
      }
    }
  }

  .hot-tabs {
    border: none;
    box-shadow: none;

    .el-tabs__header {
      margin: 20px 0 6px;
      border-bottom: none;
      background-color: transparent;

      .el-tabs__item {
        border: none;
        font-size: 16px;
        padding: 0 10px !important;
        color: #808080;
        height: 30px;
        line-height: 30px;
      }

      .el-tabs__item.is-active {
        color: #fff;
        background-color: #00BF43
      }

      .el-tabs__item:not(.is-active):hover {
        color: #808080;
      }

      .el-tabs__active-bar {
        display: none;
      }
    }

    .el-tabs__content {
      padding: 0 !important;
    }

    .el-tabs__nav-wrap::after {
      display: none;
    }
  }
}
</style>
